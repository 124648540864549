import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParamsWithTransition, useNavigateWithTransition } from '../hooks/useRouterWithTransition'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../contexts/AuthContext'
import AuthModal from './AuthModal'
import LanguageSwitcher from './LanguageSwitcher'
import DemoRequestModal from './DemoRequestModal'

export default function Header() {
  const [authModal, setAuthModal] = useState({ isOpen: false, mode: 'login' })
  const { t } = useTranslation()
  const { currentUser, logout } = useAuth()
  const navigate = useNavigateWithTransition()
  const { lang } = useParamsWithTransition()
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false)

  const navigation = [
    { 
      name: 'header.platform', 
      href: '#platform'
    },
    { name: 'header.features', href: '#agents' },
    // { name: 'header.pricing', href: '#pricing' },
    { name: 'header.about', href: '#team' },
    { name: 'header.contact', href: '#contact' },
  ]

  const openAuthModal = (mode) => {
    setAuthModal({ isOpen: true, mode })
  }

  const closeAuthModal = () => {
    setAuthModal({ ...authModal, isOpen: false })
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleNavigation = (e, href, onClick) => {
    e.preventDefault()
    if (onClick) {
      onClick(e)
      return
    }
    
    const isAnchor = href.startsWith('#')
    if (isAnchor) {
      const sectionId = href.substring(1)
      scrollToSection(sectionId)
    } else {
      navigate(`/${lang}${href}`)
    }
  }

  return (
    <>
      <Disclosure as="nav" className="bg-gray-900/5 backdrop-blur-md shadow-sm border-b border-gray-900/10 fixed w-full z-50">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <Link to={`/${lang}`} className="flex flex-shrink-0 items-center">
                    <div className="relative h-12 w-12 flex items-center justify-center">
                      <div className="absolute inset-0 bg-gradient-to-r from-indigo-400 to-purple-400 [mask-image:url('/logo.svg')] [mask-size:100%] [mask-repeat:no-repeat] [mask-position:center]"></div>
                      <img
                        className="h-12 w-12 opacity-0"
                        src="/logo.svg"
                        alt="OptiLab"
                      />
                    </div>
                    <span className="ml-3 text-3xl font-semibold bg-gradient-to-r from-indigo-400 to-purple-400 bg-clip-text text-transparent">OptiLab</span>
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <nav className="flex space-x-4 mr-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => handleNavigation(e, item.href, item.onClick)}
                        className={`rounded-md px-3.5 py-2 text-sm font-semibold text-gray-200 hover:text-white hover:bg-white/10 transition-colors ${item.className || ''}`}
                      >
                        {t(item.name)}
                      </a>
                    ))}
                  </nav>
                  <div className="flex items-center space-x-4">
                    {currentUser ? (
                      <Link
                        to={`/${lang}/console`}
                        className="rounded-md bg-gradient-to-r from-indigo-500 to-purple-500 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:from-indigo-600 hover:to-purple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 transition-all"
                      >
                        {t('header.console')}
                      </Link>
                    ) : (
                      <>
                        <button
                          onClick={(e) => {
                            openAuthModal('login')
                            setTimeout(() => e.target.blur(), 0)
                          }}
                          className="rounded-md px-3.5 py-2 text-sm font-semibold text-gray-200 hover:text-white bg-gray-900/30 hover:bg-gray-900/50 transition-colors ring-1 ring-gray-200/20 focus:outline-none focus:ring-0"
                        >
                          {t('header.signin')}
                        </button>
                        <button
                          onClick={() => openAuthModal('signup')}
                          className="rounded-md bg-gradient-to-r from-indigo-500 to-purple-500 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:from-indigo-600 hover:to-purple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 transition-all"
                        >
                          {t('header.getStarted')}
                        </button>
                      </>
                    )}
                    <div className="ml-1 border-l border-gray-200/20 pl-2">
                      <LanguageSwitcher />
                    </div>
                  </div>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-700 hover:bg-white/60 hover:text-gray-900">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    onClick={(e) => handleNavigation(e, item.href, item.onClick)}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-white/60 hover:text-gray-900"
                  >
                    {t(item.name)}
                  </Disclosure.Button>
                ))}
                <div className="mt-4 flex flex-col space-y-2 px-3">
                  {currentUser ? (
                    <Link
                      to={`/${lang}/console`}
                      className="w-full rounded-md bg-gradient-to-r from-indigo-500 to-purple-500 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:from-indigo-600 hover:to-purple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 transition-all text-center"
                    >
                      {t('header.console')}
                    </Link>
                  ) : (
                    <>
                      <button
                        onClick={() => openAuthModal('login')}
                        className="w-full rounded-md px-3.5 py-2 text-sm font-semibold text-gray-700 hover:text-gray-900 bg-white/30 hover:bg-white/60 transition-colors ring-1 ring-gray-200/20 focus:outline-none focus:ring-0"
                      >
                        {t('header.signin')}
                      </button>
                      <button
                        onClick={() => openAuthModal('signup')}
                        className="w-full rounded-md bg-gradient-to-r from-indigo-500 to-purple-500 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:from-indigo-600 hover:to-purple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 transition-all"
                      >
                        {t('header.getStarted')}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <AuthModal
        isOpen={authModal.isOpen}
        mode={authModal.mode}
        onClose={closeAuthModal}
      />
      <DemoRequestModal isOpen={isDemoModalOpen} setIsOpen={setIsDemoModalOpen} />
    </>
  )
}
