import { FaGoogle } from 'react-icons/fa';
import { SiVk, SiTelegram } from 'react-icons/si';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { signInWithGoogle, signInWithVK, signInWithTelegram } from '../../services/supabase';
import toast from 'react-hot-toast';

const SocialLoginButtons = () => {
  const { t, i18n } = useTranslation();
  const isRussian = i18n.language === 'ru';
  const [loading, setLoading] = useState('');
  const [error, setError] = useState('');

  const handleSocialLogin = async (provider) => {
    try {
      setLoading(provider);
      setError('');
      
      let data;
      switch (provider) {
        case 'google':
          data = await signInWithGoogle();
          break;
        case 'vk':
          data = await signInWithVK();
          break;
        case 'telegram':
          data = await signInWithTelegram();
          break;
        default:
          throw new Error('Unsupported provider');
      }
      
      if (data.error) throw data.error;
    } catch (error) {
      setError(error.message);
      toast.error(error.message);
    } finally {
      setLoading('');
    }
  };

  return (
    <div className="space-y-3">
      <button
        onClick={() => handleSocialLogin('google')}
        disabled={!!loading}
        className="flex w-full justify-center items-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0 disabled:opacity-50"
      >
        <FaGoogle className="h-5 w-5 text-red-600" />
        <span>{loading === 'google' ? t('common.loading') : t('auth.socialAuth.google')}</span>
      </button>

      {isRussian && (
        <>
          <button
            onClick={() => handleSocialLogin('vk')}
            disabled={!!loading}
            className="flex w-full justify-center items-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0 disabled:opacity-50"
          >
            <SiVk className="h-5 w-5 text-blue-600" />
            <span>{loading === 'vk' ? t('common.loading') : t('auth.socialAuth.vk')}</span>
          </button>

          <button
            onClick={() => handleSocialLogin('telegram')}
            disabled={!!loading}
            className="flex w-full justify-center items-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0 disabled:opacity-50"
          >
            <SiTelegram className="h-5 w-5 text-blue-500" />
            <span>{loading === 'telegram' ? t('common.loading') : t('auth.socialAuth.telegram')}</span>
          </button>
        </>
      )}

      {error && (
        <div className="rounded-md bg-red-50 p-4 mt-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{t('auth.error.socialLoginFailed')}</h3>
              <div className="mt-2 text-sm text-red-700">{error}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SocialLoginButtons;
