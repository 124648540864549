import { useTranslation } from 'react-i18next';
import alexeyImage from '../assets/team/Alexey.png';
import vitalyImage from '../assets/team/Vitaly.jpeg';
import pavelImage from '../assets/team/Pavel.jpeg';

const team = [
  {
    name: {
      en: 'Alexey Denisov',
      ru: 'Алексей Денисов'
    },
    role: {
      en: 'CDBO',
      ru: 'CDBO'
    },
    description: {
      en: 'IT Director with expertise in optimizing strategies and customer experience.',
      ru: 'IT-директор, эксперт по оптимизации стратегий и клиентского опыта.'
    },
    imageUrl: alexeyImage,
  },
  {
    name: {
      en: 'Vitaly Klimenko',
      ru: 'Виталий Клименко'
    },
    role: {
      en: 'CPO',
      ru: 'CPO'
    },
    description: {
      en: 'Product Director with proven success in IT products and fintech.',
      ru: 'Директор по продукту с успешным опытом в IT-продуктах и финтехе.'
    },
    imageUrl: vitalyImage,
  },
  {
    name: {
      en: 'Pavel Dzhaoshvili',
      ru: 'Павел Джаошвили'
    },
    role: {
      en: 'CTO',
      ru: 'CTO'
    },
    description: {
      en: 'High-load IT projects expert, Youla co-founder.',
      ru: 'Эксперт по высоконагруженным IT-проектам, сооснователь Youla.'
    },
    imageUrl: pavelImage,
  },
];

export default function Team() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.startsWith('ru') ? 'ru' : 'en';

  return (
    <div id="team" className="bg-gray-900 py-10 sm:py-12" style={{ scrollMarginTop: '20px' }}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-base font-semibold leading-7 bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-purple-500">
            {t('team.title')}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {t('team.subtitle')}
          </p>
          <p className="mt-4 text-lg leading-8 text-gray-300">
            {t('team.description')}
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {team.map((person) => (
            <li key={person.name.ru}>
              <div className="relative group">
                {/* Image Container */}
                <div className="relative mx-auto h-56 w-44 overflow-hidden rounded-2xl bg-gray-800 transition duration-300 ease-in-out group-hover:scale-[1.02] group-hover:brightness-110">
                  <img
                    className="h-full w-full object-cover object-top"
                    src={person.imageUrl}
                    alt={person.name.ru}
                  />
                </div>

                {/* Content Container */}
                <div className="relative mt-4 text-center">
                  <div className="flex flex-col items-center gap-1">
                    <h3 className="text-lg font-semibold leading-7 tracking-tight text-white">
                      {person.name[currentLang]}
                    </h3>
                    <p className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                      {person.role[currentLang]}
                    </p>
                  </div>
                  <p className="mt-2 text-sm leading-6 text-gray-300">
                    {person.description[currentLang]}
                  </p>
                </div>

                {/* Hover Effect Overlay */}
                <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-2xl bg-gradient-to-r from-indigo-500/5 to-purple-500/5 transition group-hover:block" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
