import { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
// import Testimonials from '../components/Testimonials'
// import Stats from '../components/Stats'
import AuthModal from '../components/AuthModal'
// import ClientLogos from '../components/ClientLogos'
import AIAgents from '../components/AIAgents'
// import AITrends from '../components/AITrends'
// import Pricing from '../components/Pricing'
import Team from '../components/Team'
import Contact from '../components/Contact'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { useLanguage } from '../contexts/LanguageContext'
import DemoRequestModal from '../components/DemoRequestModal'

export default function Landing() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { isRussian } = useLanguage();
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);
  const [authModal, setAuthModal] = useState({ isOpen: false, mode: 'login' });

  const openAuthModal = (mode) => {
    setAuthModal({ isOpen: true, mode });
  };

  const closeAuthModal = () => {
    setAuthModal({ isOpen: false, mode: 'login' });
  };

  const switchAuthMode = (newMode) => {
    setAuthModal({ isOpen: true, mode: newMode });
  };

  return (
    <div className="relative isolate bg-gray-900 min-w-[100vw] max-w-[100vw] overflow-x-hidden">
      <Header />
      
      {/* Hero Section */}
      <div id="platform" className="relative isolate pt-14 w-[100vw]">
        {/* Background decorative elements */}
        <div className="absolute -top-40 -z-10 transform-gpu overflow-visible blur-3xl sm:-top-80 w-[200vw] left-[-50vw]"
          aria-hidden="true">
          <div className="relative aspect-[1155/678] w-full rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        {/* Hero Content */}
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20">
          <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
            <div className="flex">
              <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-100 ring-1 ring-gray-700/10 hover:ring-gray-700/20">
                <span className="font-semibold text-indigo-400">{t('hero.new')}</span>
                <span className="h-4 w-px bg-gray-600/20" aria-hidden="true" />
                <button
                  onClick={() => setIsDemoModalOpen(true)}
                  className="flex items-center gap-x-1 cursor-pointer hover:text-white"
                >
                  {t('hero.explore')}
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-10 max-w-xl">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                {t('hero.title')}
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                {t('hero.description')}
              </p>
              <div className="mt-8 flex items-center gap-x-6">
                <button
                  onClick={() => setIsDemoModalOpen(true)}
                  className="rounded-md bg-indigo-600 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t('hero.explore')}
                </button>
                <a href="#features" className="text-sm font-semibold leading-6 text-white hover:text-indigo-400 transition-colors">
                  {t('hero.learnMore')} <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-none">
            <img
              src={i18n.language === 'ru' ? '/images/ai-platform-hero-ru.svg' : '/images/ai-platform-hero-en.svg'}
              alt={t('hero.image.alt')}
              className="w-full max-w-[36rem] rounded-xl bg-white/5 shadow-2xl ring-1 ring-white/10 transition-transform hover:scale-105"
            />
          </div>
        </div>

        {/* Bottom decorative element */}
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] pointer-events-none"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem] pointer-events-none"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>

      {/* Features section */}
      <div id="features" className="relative z-10 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-400">{t('features.title')}</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {t('features.subtitle')}
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
              {[
                {
                  name: t('features.items.sales.title'),
                  description: t('features.items.sales.description'),
                  icon: (
                    <div className="rounded-lg bg-indigo-600/10 p-2 ring-1 ring-indigo-600/25">
                      <svg className="h-6 w-6 text-indigo-400" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm0 3h.008v.008H18V15zm0 3h.008v.008H18V18z" />
                      </svg>
                    </div>
                  ),
                },
                {
                  name: t('features.items.support.title'),
                  description: t('features.items.support.description'),
                  icon: (
                    <div className="rounded-lg bg-indigo-600/10 p-2 ring-1 ring-indigo-600/25">
                      <svg className="h-6 w-6 text-indigo-400" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0111.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                      </svg>
                    </div>
                  ),
                },
                {
                  name: t('features.items.legal.title'),
                  description: t('features.items.legal.description'),
                  icon: (
                    <div className="rounded-lg bg-indigo-600/10 p-2 ring-1 ring-indigo-600/25">
                      <svg className="h-6 w-6 text-indigo-400" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                      </svg>
                    </div>
                  ),
                },
                {
                  name: t('features.items.rag.title'),
                  description: t('features.items.rag.description'),
                  icon: (
                    <div className="rounded-lg bg-indigo-600/10 p-2 ring-1 ring-indigo-600/25">
                      <svg className="h-6 w-6 text-indigo-400" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                      </svg>
                    </div>
                  ),
                },
              ].map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                    {feature.icon}
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* Commented out Trusted Companies Section for later use
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {t('trusted.title')}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            {t('trusted.description')}
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg"
            alt="Tuple"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://tailwindui.com/img/logos/reform-logo-gray-400.svg"
            alt="Reform"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://tailwindui.com/img/logos/savvycal-logo-gray-400.svg"
            alt="SavvyCal"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src="https://tailwindui.com/img/logos/laravel-logo-gray-400.svg"
            alt="Laravel"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
            alt="Transistor"
            width={158}
            height={48}
          />
        </div>
      </div>
      */}

      {/* Stats section - temporarily disabled */}
      {/* <Stats /> */}

      {/* AI Trends section - temporarily disabled */}
      {/* <AITrends /> */}

      {/* AI Agents section */}
      <AIAgents />

      {/* Pricing section */}
      {/* <Pricing /> */}

      {/* Team section */}
      <Team />

      {/* Client Logos section - temporarily disabled */}
      {/* <ClientLogos /> */}

      {/* Contact section */}
      <Contact />

      {/* Footer */}
      <Footer />

      {/* Auth Modal */}
      <AuthModal
        isOpen={authModal.isOpen}
        mode={authModal.mode}
        onClose={closeAuthModal}
        onSwitchMode={switchAuthMode}
      />

      {/* Demo Request Modal */}
      <DemoRequestModal isOpen={isDemoModalOpen} setIsOpen={setIsDemoModalOpen} />
    </div>
  )
}
