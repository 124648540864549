import { useTranslation } from 'react-i18next'
import BackNavigation from '../components/BackNavigation'

export default function PrivacyPolicy() {
  const { t } = useTranslation()

  return (
    <div className="bg-gray-900 min-h-screen">
      <div className="mx-auto max-w-4xl px-6 py-24 sm:py-32 lg:px-8">
        <BackNavigation />
        <article className="prose prose-lg prose-invert mx-auto">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl mb-8">
            {t('privacy.title')}
          </h1>
          
          <div className="text-gray-400 mb-12">
            {t('privacy.lastUpdated')}
          </div>

          <div className="space-y-12">
            <section>
              <h2 className="text-2xl font-bold text-white mb-4">{t('privacy.section1.title')}</h2>
              <div className="text-gray-300 leading-relaxed">
                {t('privacy.section1.content')}
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-white mb-4">{t('privacy.section2.title')}</h2>
              <div className="text-gray-300 leading-relaxed">
                {t('privacy.section2.content')}
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-white mb-4">{t('privacy.section3.title')}</h2>
              <div className="text-gray-300 leading-relaxed">
                {t('privacy.section3.content')}
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-white mb-4">{t('privacy.section4.title')}</h2>
              <div className="text-gray-300 leading-relaxed">
                {t('privacy.section4.content')}
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-white mb-4">{t('privacy.section5.title')}</h2>
              <div className="text-gray-300 leading-relaxed">
                {t('privacy.section5.content')}
              </div>
            </section>
          </div>
        </article>
      </div>
    </div>
  )
}
