import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../contexts/AuthContext'
import VerificationCodeModal from './VerificationCodeModal'
import NewPasswordModal from './NewPasswordModal'

export default function ResetPasswordModal({ isOpen, onClose }) {
  const { t } = useTranslation()
  const { initiatePasswordReset, verifyPasswordReset, completePasswordReset, resendVerificationCode } = useAuth()
  
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showVerificationModal, setShowVerificationModal] = useState(false)
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false)
  const [isResending, setIsResending] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {
      setLoading(true)
      setError('')
      await initiatePasswordReset(email)
      setShowVerificationModal(true)
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleVerifyCode = async (code) => {
    try {
      await verifyPasswordReset(code)
      setShowVerificationModal(false)
      setShowNewPasswordModal(true)
    } catch (error) {
      throw error
    }
  }

  const handleResendCode = async () => {
    try {
      setIsResending(true)
      await resendVerificationCode()
    } catch (error) {
      throw error
    } finally {
      setIsResending(false)
    }
  }

  const handleSetNewPassword = async (password) => {
    try {
      await completePasswordReset(password)
      onClose()
    } catch (error) {
      throw error
    }
  }

  const handleClose = () => {
    setEmail('')
    setError('')
    setShowVerificationModal(false)
    setShowNewPasswordModal(false)
    onClose()
  }

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 cursor-pointer border-0 outline-none ring-0 focus:ring-0 focus:outline-none hover:bg-white"
                      onClick={handleClose}
                    >
                      <span className="sr-only">{t('common.close')}</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <div className="text-center">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {t('auth.resetPassword.title')}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t('auth.resetPassword.description')}
                        </p>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit} className="mt-5">
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                          {t('auth.resetPassword.emailLabel')}
                        </label>
                        <div className="mt-2">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={t('auth.resetPassword.emailPlaceholder')}
                            className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                          />
                        </div>
                      </div>
                      {error && (
                        <p className="mt-2 text-sm text-center text-red-600">
                          {error}
                        </p>
                      )}
                      <div className="mt-5">
                        <button
                          type="submit"
                          disabled={loading}
                          className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {loading ? (
                            <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent" />
                          ) : (
                            t('auth.resetPassword.submit')
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <VerificationCodeModal
        isOpen={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
        onVerify={handleVerifyCode}
        onResendCode={handleResendCode}
        isResending={isResending}
        type="recovery"
      />

      <NewPasswordModal
        isOpen={showNewPasswordModal}
        onClose={() => setShowNewPasswordModal(false)}
        onSubmit={handleSetNewPassword}
      />
    </>
  )
}
