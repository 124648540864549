import React, { createContext, useContext, useState, useEffect } from 'react'
import { supabase, signInWithGoogle } from '../services/supabase'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Check active sessions and sets the user
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null)
      setLoading(false)
    })

    // Listen for changes on auth state (logged in, signed out, etc.)
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      setUser(session?.user ?? null)
      setLoading(false)
    })

    return () => subscription.unsubscribe()
  }, [])

  const handleSocialLogin = async (provider) => {
    try {
      setError(null)
      setLoading(true)
      
      let result;
      
      switch(provider) {
        case 'google':
          result = await signInWithGoogle()
          break;
        case 'vk':
          result = await supabase.auth.signInWithOAuth({
            provider: 'vk',
            options: {
              redirectTo: `${window.location.origin}/auth/callback`,
              scopes: 'email'
            }
          })
          break;
        default:
          throw new Error(`Unsupported provider: ${provider}`)
      }

      if (result.error) throw result.error
      
      return result
    } catch (error) {
      console.error(`${provider} login error:`, error)
      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleEmailLogin = async (email, password) => {
    try {
      setError(null)
      setLoading(true)
      
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      })
      
      if (error) throw error
      
      return data
    } catch (error) {
      console.error('Email login error:', error)
      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleSignUp = async (email, password) => {
    try {
      setError(null)
      setLoading(true)
      
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`
        }
      })
      
      if (error) throw error
      
      return data
    } catch (error) {
      console.error('Sign up error:', error)
      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleLogout = async () => {
    try {
      setError(null)
      setLoading(true)
      
      const { error } = await supabase.auth.signOut()
      if (error) throw error
      
      setUser(null)
    } catch (error) {
      console.error('Logout error:', error)
      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error,
        handleSocialLogin,
        handleEmailLogin,
        handleSignUp,
        handleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
