import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../contexts/AuthContext'
import ResetPasswordModal from './ResetPasswordModal'

export default function AuthModal({ isOpen, onClose, mode = 'login', onSwitchMode }) {
  const { t, i18n } = useTranslation()
  const { login, signup, handleSocialLogin, handleTelegramCallback } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [emailError, setEmailError] = useState('')
  const [emailTouched, setEmailTouched] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [currentMode, setCurrentMode] = useState(mode)
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)
  const [socialLoading, setSocialLoading] = useState('')

  useEffect(() => {
    if (isOpen) {
      setCurrentMode(mode)
      // Reset form state when modal opens
      setEmail('')
      setPassword('')
      setEmailError('')
      setPasswordError('')
      setEmailTouched(false)
      setShowPassword(false)
      setError(null)
      setSocialLoading('')
      
      // Initialize Telegram login widget
      window.onTelegramAuth = (user) => {
        handleTelegramCallback(user).catch((error) => {
          console.error('Telegram auth error:', error)
          setError(error.message)
          setSocialLoading('')
        })
      }

      return () => {
        window.onTelegramAuth = null
        document.querySelectorAll('script[src*="telegram-widget.js"]').forEach(s => s.remove())
      }
    }
  }, [isOpen, handleTelegramCallback])

  const initTelegramLogin = () => {
    setSocialLoading('telegram')

    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-widget.js?22'
    script.setAttribute('data-telegram-login', import.meta.env.VITE_TELEGRAM_BOT_USERNAME)
    script.setAttribute('data-size', 'medium')
    script.setAttribute('data-radius', '8')
    script.setAttribute('data-request-access', 'write')
    script.setAttribute('data-userpic', 'false')
    script.setAttribute('data-onauth', 'onTelegramAuth(user)')
    script.setAttribute('data-auth-url', 'https://optilab.ai/auth/telegram/callback')
    script.async = true

    const container = document.createElement('div')
    container.style.position = 'fixed'
    container.style.top = '50%'
    container.style.left = '50%'
    container.style.transform = 'translate(-50%, -50%)'
    container.style.zIndex = '9999'
    container.style.backgroundColor = 'white'
    container.style.padding = '20px'
    container.style.borderRadius = '8px'
    container.style.boxShadow = '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
    
    const backdrop = document.createElement('div')
    backdrop.style.position = 'fixed'
    backdrop.style.top = '0'
    backdrop.style.left = '0'
    backdrop.style.width = '100%'
    backdrop.style.height = '100%'
    backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
    backdrop.style.zIndex = '9998'
    
    container.appendChild(script)
    document.body.appendChild(backdrop)
    document.body.appendChild(container)
    
    backdrop.addEventListener('click', () => {
      container.remove()
      backdrop.remove()
      setSocialLoading('')
    })
  }

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email) && !email.includes('+')
  }

  const validatePassword = (password) => {
    if (password.length < 6 || password.length > 24) {
      return 'Password must be between 6 and 24 characters'
    }
    
    // Check for Latin letters
    if (!/[a-zA-Z]/.test(password)) {
      return 'Password must contain at least one Latin letter'
    }
    
    return ''
  }

  const handleEmailChange = (e) => {
    const newEmail = e.target.value
    // Filter out non-Latin characters in real-time
    const filteredEmail = newEmail.replace(/[^a-zA-Z0-9@._-]/g, '')
    setEmail(filteredEmail)
    
    // Only show error if the field has been touched and left
    if (emailTouched) {
      if (filteredEmail && !validateEmail(filteredEmail)) {
        setEmailError(t('auth.signUp.error.invalidEmail'))
      } else {
        setEmailError('')
      }
    }
  }

  const handleEmailBlur = () => {
    setEmailTouched(true)
    if (email && !validateEmail(email)) {
      setEmailError(t('auth.signUp.error.invalidEmail'))
    } else {
      setEmailError('')
    }
  }

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value
    setPassword(newPassword)
    const error = validatePassword(newPassword)
    setPasswordError(error)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    if (emailError || passwordError) return

    try {
      setLoading(true)
      setError(null)

      if (currentMode === 'login') {
        await login(email, password)
      } else {
        await signup(name, email, password)
      }

      onClose()
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSocialLoginClick = async (provider) => {
    try {
      setSocialLoading(provider)
      setError(null)
      await handleSocialLogin(provider)
      onClose()
    } catch (error) {
      setError(t('auth.error.socialLoginFailed'))
      console.error('Social login error:', error)
    } finally {
      setSocialLoading('')
    }
  }

  const switchMode = () => {
    const newMode = currentMode === 'login' ? 'signup' : 'login';
    setCurrentMode(newMode);
    onSwitchMode?.(newMode);
    // Reset form state
    setEmail('');
    setPassword('');
    setName('');
    setEmailError('');
    setPasswordError('');
    setEmailTouched(false);
    setError(null);
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      onClick={onClose}
                      style={{ background: 'none', border: 'none', padding: 0, outline: 'none', cursor: 'pointer' }}
                      className="focus:outline-none focus:ring-0"
                    >
                      <span className="sr-only">{t('auth.modal.close')}</span>
                      <XMarkIcon className="h-6 w-6 text-gray-400 hover:text-gray-500 transition-colors duration-200" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="text-center text-base font-semibold leading-6 text-gray-900"
                    >
                      {t(currentMode === 'login' ? 'auth.signIn.title' : 'auth.signUp.title')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-center text-gray-500">
                        {t(currentMode === 'login' ? 'auth.signIn.subtitle' : 'auth.signUp.subtitle')}
                      </p>
                    </div>
                    <form 
                      onSubmit={handleSubmit} 
                      className="mt-6 space-y-6"
                      method="post"
                      autoComplete="on"
                    >
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                          {t(currentMode === 'login' ? 'auth.signIn.email' : 'auth.signUp.email')}
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete={currentMode === 'login' ? 'username' : 'email'}
                            required
                            value={email}
                            onChange={handleEmailChange}
                            onBlur={handleEmailBlur}
                            placeholder={t(currentMode === 'login' ? 'auth.signIn.emailPlaceholder' : 'auth.signUp.emailPlaceholder')}
                            className={`block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ${
                              emailTouched && emailError ? 'ring-red-300' : 'ring-gray-300'
                            } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                          />
                          {emailError && (
                            <p className="mt-2 text-sm text-red-600">
                              {t('auth.signUp.error.invalidEmail')}
                            </p>
                          )}
                        </div>
                      </div>

                      {currentMode === 'signup' && (
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('auth.signUp.fullName')}
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder={t('auth.signUp.fullNamePlaceholder')}
                            />
                          </div>
                        </div>
                      )}

                      <div>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                          {t(currentMode === 'login' ? 'auth.signIn.password' : 'auth.signUp.password')}
                        </label>
                        <div className="relative mt-2">
                          <input
                            id="password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete={currentMode === 'login' ? 'current-password' : 'new-password'}
                            required
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder={t(currentMode === 'login' ? 'auth.signIn.passwordPlaceholder' : 'auth.signUp.passwordPlaceholder')}
                            className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pr-14"
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center pr-4">
                            <button
                              type="button"
                              style={{ background: 'none', border: 'none', padding: '6px', outline: 'none' }}
                              className="rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-0"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <EyeSlashIcon className="h-5 w-5 text-gray-400 hover:text-gray-600" aria-hidden="true" />
                              ) : (
                                <EyeIcon className="h-5 w-5 text-gray-400 hover:text-gray-600" aria-hidden="true" />
                              )}
                            </button>
                          </div>
                        </div>
                        {passwordError && (
                          <p className="mt-2 text-sm text-red-600">
                            {t('auth.signUp.error.weakPassword')}
                          </p>
                        )}
                      </div>

                      {currentMode === 'login' && (
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <input
                              id="remember-me"
                              name="remember-me"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                              {t('auth.signIn.rememberMe')}
                            </label>
                          </div>

                          <div className="text-sm leading-6">
                            <button
                              type="button"
                              onClick={() => setIsResetModalOpen(true)}
                              className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 focus:outline-none active:outline-none focus:ring-0"
                            >
                              {t('auth.signIn.forgotPassword')}
                            </button>
                          </div>
                        </div>
                      )}

                      <div>
                        <button
                          type="submit"
                          disabled={loading}
                          className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {loading ? (
                            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                          ) : (
                            t(currentMode === 'login' ? 'auth.signIn.submit' : 'auth.signUp.submit')
                          )}
                        </button>
                      </div>
                    </form>

                    {error && (
                      <div className="mt-4">
                        <p className="text-sm text-red-600">
                          {error}
                        </p>
                      </div>
                    )}

                    <div className="mt-6">
                      <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                          <span className="bg-white px-2 text-gray-500">
                            {t(currentMode === 'login' ? 'auth.signIn.or' : 'auth.signUp.or')}
                          </span>
                        </div>
                      </div>

                      <div className="mt-6 grid grid-cols-3 gap-3">
                        <button
                          type="button"
                          onClick={() => handleSocialLoginClick('google')}
                          disabled={!!socialLoading}
                          className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {socialLoading === 'google' ? (
                            <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-300 border-t-indigo-600" />
                          ) : (
                            <img className="h-5 w-5" src="/google.svg" alt={t('auth.social.google')} />
                          )}
                        </button>

                        <button
                          type="button"
                          onClick={initTelegramLogin}
                          disabled={!!socialLoading}
                          className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent disabled:opacity-50 disabled:cursor-not-allowed"
                          title={t('auth.social.telegram')}
                          aria-label={t('auth.social.telegram')}
                        >
                          {socialLoading === 'telegram' ? (
                            <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-300 border-t-indigo-600" />
                          ) : (
                            <img className="h-5 w-5" src="/telegram.svg" alt={t('auth.social.telegram')} />
                          )}
                        </button>

                        {i18n.language === 'ru' ? (
                          <button
                            type="button"
                            onClick={() => handleSocialLoginClick('vk')}
                            disabled={!!socialLoading}
                            className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            {socialLoading === 'vk' ? (
                              <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-300 border-t-indigo-600" />
                            ) : (
                              <img className="h-5 w-5" src="/vk.svg" alt={t('auth.social.vk')} />
                            )}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleSocialLoginClick('facebook')}
                            disabled={!!socialLoading}
                            className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            {socialLoading === 'facebook' ? (
                              <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-300 border-t-indigo-600" />
                            ) : (
                              <img className="h-5 w-5" src="/facebook.svg" alt={t('auth.social.facebook')} />
                            )}
                          </button>
                        )}
                      </div>

                      <div className="mt-6 text-center text-sm">
                        <button
                          type="button"
                          onClick={switchMode}
                          className="font-semibold text-indigo-600 hover:text-indigo-500 focus:outline-none active:outline-none focus:ring-0"
                        >
                          {t(currentMode === 'login' ? 'auth.modal.switchToSignUp' : 'auth.modal.switchToSignIn')}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <ResetPasswordModal
        isOpen={isResetModalOpen}
        onClose={() => setIsResetModalOpen(false)}
      />
    </>
  )
}
