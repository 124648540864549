import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useParamsWithTransition, useNavigateWithTransition } from '../hooks/useRouterWithTransition'
import { Menu, Transition } from '@headlessui/react'
import { GlobeAltIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const languages = {
  en: 'EN',
  ru: 'RU'
}

export default function LanguageSwitcher() {
  const { i18n } = useTranslation()
  const navigate = useNavigateWithTransition()
  const { lang } = useParamsWithTransition()

  const changeLanguage = (newLang) => {
    i18n.changeLanguage(newLang)
    localStorage.setItem('i18nextLng', newLang)
    const currentPath = window.location.pathname
    const newPath = currentPath.replace(`/${lang}`, `/${newLang}`)
    navigate(newPath)
  }

  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className="group flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-900/30 transition-colors">
        <GlobeAltIcon className="h-5 w-5 text-gray-200 group-hover:text-white" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-1 w-8 origin-top-right rounded-md bg-gray-900/95 backdrop-blur-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
          {Object.entries(languages).map(([code, label]) => (
            <Menu.Item key={code}>
              {({ active }) => (
                <button
                  onClick={() => changeLanguage(code)}
                  className={`${
                    active ? 'bg-gray-800/50' : ''
                  } ${
                    i18n.language === code ? 'text-white' : 'text-gray-300'
                  } w-full text-center text-xs font-medium py-1.5`}
                >
                  {label}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
