import { useTranslation } from 'react-i18next';
import { 
  ChatBubbleLeftRightIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  ArrowPathIcon,
  DocumentTextIcon,
  CpuChipIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline';

const agents = [
  {
    name: {
      en: 'AI Sales Assistant',
      ru: 'AI Ассистент Продаж'
    },
    description: {
      en: 'Handles customer inquiries 24/7, qualifies leads, and provides personalized product recommendations.',
      ru: 'Обрабатывает запросы клиентов 24/7, квалифицирует лиды и предоставляет персонализированные рекомендации по продуктам.'
    },
    icon: ChatBubbleLeftRightIcon,
    benefits: {
      en: [
        'Instant response to customer queries',
        'Lead qualification and scoring',
        'Personalized product recommendations',
        'Integration with CRM systems',
        'Multi-language support'
      ],
      ru: [
        'Мгновенные ответы на запросы клиентов',
        'Квалификация и оценка лидов',
        'Персонализированные рекомендации',
        'Интеграция с CRM системами',
        'Поддержка нескольких языков'
      ]
    }
  },
  {
    name: {
      en: 'AI Support Manager',
      ru: 'AI Менеджер Поддержки'
    },
    description: {
      en: 'Manages technical support requests, resolves incidents, and provides cloud infrastructure assistance.',
      ru: 'Управляет запросами технической поддержки, решает инциденты и помогает с облачной инфраструктурой.'
    },
    icon: CloudArrowUpIcon,
    benefits: {
      en: [
        'Quick incident resolution',
        'AWS infrastructure expertise',
        'Automated troubleshooting',
        'Performance optimization',
        'Security compliance'
      ],
      ru: [
        'Быстрое решение инцидентов',
        'Экспертиза в AWS инфраструктуре',
        'Автоматизированное устранение неполадок',
        'Оптимизация производительности',
        'Соответствие требованиям безопасности'
      ]
    }
  },
  {
    name: {
      en: 'Legal Assistant',
      ru: 'Юридический Ассистент'
    },
    description: {
      en: 'Analyzes court decisions and case materials, providing suggestions for filing appeals and complaints.',
      ru: 'Анализирует судебные решения и материалы дел, предоставляет рекомендации по подаче апелляций и жалоб.'
    },
    icon: DocumentTextIcon,
    benefits: {
      en: [
        'Court decision analysis',
        'Appeal preparation assistance',
        'Legal precedent research',
        'Document automation',
        'Compliance checking'
      ],
      ru: [
        'Анализ судебных решений',
        'Помощь в подготовке апелляций',
        'Исследование прецедентов',
        'Автоматизация документов',
        'Проверка соответствия'
      ]
    }
  },
  {
    name: {
      en: 'RAG System',
      ru: 'RAG Система'
    },
    description: {
      en: 'Advanced Retrieval-Augmented Generation system for accurate and context-aware information processing.',
      ru: 'Продвинутая система генерации с извлечением информации для точной обработки данных с учетом контекста.'
    },
    icon: CpuChipIcon,
    benefits: {
      en: [
        'Context-aware responses',
        'Accurate information retrieval',
        'Knowledge base integration',
        'Real-time data processing',
        'Custom domain adaptation'
      ],
      ru: [
        'Ответы с учетом контекста',
        'Точное извлечение информации',
        'Интеграция базы знаний',
        'Обработка данных в реальном времени',
        'Адаптация под конкретную область'
      ]
    }
  }
];

export default function AIAgents() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.startsWith('ru') ? 'ru' : 'en';

  return (
    <div id="agents" className="relative isolate overflow-hidden py-24 sm:py-32">
      {/* Background gradient */}
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),theme(colors.gray.900))] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-gray-900/80 shadow-xl shadow-indigo-600/10 ring-1 ring-white/10 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400 animate-fade-in">
            {t('agents.title')}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 bg-clip-text text-transparent">
            {t('agents.subtitle')}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            {t('agents.description')}
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            {agents.map((agent) => (
              <div 
                key={agent.name[currentLang]} 
                className="relative group flex flex-col bg-gray-900/50 rounded-3xl p-8 ring-1 ring-white/10 hover:ring-indigo-500/50 transition-all duration-300 hover:shadow-[0_0_30px_rgba(79,70,229,0.1)] hover:-translate-y-1"
              >
                {/* Decorative gradient */}
                <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-indigo-500 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
                
                <dt className="flex items-center gap-x-4 text-lg font-semibold leading-7 text-white">
                  <div className="rounded-lg bg-indigo-500/10 p-3 ring-1 ring-indigo-500/20 group-hover:bg-indigo-500/20 group-hover:ring-indigo-500/30 transition-colors">
                    <agent.icon
                      className="h-6 w-6 text-indigo-400 group-hover:text-indigo-300 transition-colors"
                      aria-hidden="true"
                    />
                  </div>
                  <span className="bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent">
                    {agent.name[currentLang]}
                  </span>
                </dt>

                <dd className="mt-6 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto text-base leading-7">
                    {agent.description[currentLang]}
                  </p>
                  <ul className="mt-8 space-y-4 border-t border-white/5 pt-8">
                    {agent.benefits[currentLang].map((benefit) => (
                      <li key={benefit} className="flex items-center gap-x-3 group/item">
                        <CheckCircleIcon
                          className="h-5 w-5 flex-none text-indigo-400 group-hover/item:text-indigo-300 transition-colors"
                          aria-hidden="true"
                        />
                        <span className="text-gray-300 group-hover/item:text-gray-200 transition-colors">
                          {benefit}
                        </span>
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
