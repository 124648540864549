import React, { createContext, useContext, useState, useEffect } from 'react';
import i18n from '../i18n';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const [isRussian, setIsRussian] = useState(i18n.language === 'ru');

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setIsRussian(lng === 'ru');
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const value = {
    isRussian,
    setIsRussian,
    toggleLanguage: () => {
      const newLang = isRussian ? 'en' : 'ru';
      i18n.changeLanguage(newLang);
      setIsRussian(!isRussian);
    }
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}
