import { Link } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

export default function BackNavigation() {
  const { t } = useTranslation()
  
  return (
    <Link
      to="/"
      className="inline-flex items-center text-gray-400 hover:text-white transition-colors mb-8"
    >
      <ChevronLeftIcon className="h-5 w-5 mr-2" />
      {t('common.backToMain')}
    </Link>
  )
}
