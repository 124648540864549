import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://auth.optilab.ai'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24ifQ.625_WdcF3KHqz5amU0x2X5WWHP-OEs_4qj0ssLNHzTs'

if (!supabaseUrl) throw new Error('supabaseUrl is required')
if (!supabaseAnonKey) throw new Error('supabaseAnonKey is required')

const supabaseOptions = {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce',
    storage: window.localStorage,
    storageKey: 'supabase.auth.token',
    debug: true,
    cookieOptions: {
      domain: '.optilab.ai',
      sameSite: 'lax',
      secure: true,
      path: '/'
    }
  }
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, supabaseOptions)

export const signInWithGoogle = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: 'https://optilab.ai/auth/callback',
      scopes: 'email profile',
      queryParams: {
        access_type: 'offline',
        prompt: 'consent'
      }
    }
  })
  if (error) {
    console.error('Google auth error:', error)
    throw error
  }
  return data
}

export const signInWithVK = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'vkontakte',
    options: {
      redirectTo: 'https://optilab.ai/auth/callback',
      scopes: 'email'
    }
  })
  if (error) {
    console.error('VK auth error:', error)
    throw error
  }
  return data
}

export const signInWithFacebook = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'facebook',
    options: {
      redirectTo: `${window.location.origin}/auth/callback`,
      scopes: 'email',
      skipBrowserRedirect: false,
    }
  })
  
  if (error) throw error
  return data
}

export const signInWithEmail = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password
  })
  
  if (error) throw error
  return data
}

export const signUp = async (email, password) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      emailRedirectTo: `${window.location.origin}/auth/callback`
    }
  })
  
  if (error) throw error
  return data
}

export const signInWithTelegram = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'telegram',
    options: {
      redirectTo: 'https://optilab.ai/auth/callback'
    }
  })
  if (error) {
    console.error('Telegram auth error:', error)
    throw error
  }
  return data
}

export const signInWithTelegramAuth = async (authData) => {
  try {
    if (!authData || !authData.id) {
      throw new Error('Invalid Telegram auth data')
    }

    const { data, error } = await supabase.functions.invoke('telegramAuth', {
      body: {
        ...authData,
        timestamp: Date.now()
      }
    })
    
    if (error) {
      console.error('Telegram auth validation error:', error)
      throw error
    }
    return data
  } catch (error) {
    console.error('Failed to validate Telegram auth:', error)
    throw error
  }
}

export const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  if (error) throw error
}

export const getCurrentSession = async () => {
  try {
    const { data: { session }, error } = await supabase.auth.getSession()
    if (error) throw error
    return session
  } catch (error) {
    console.error('Error getting current session:', error)
    return null
  }
}

export const getCurrentUser = async () => {
  try {
    const session = await getCurrentSession()
    return session?.user || null
  } catch (error) {
    console.error('Error getting current user:', error)
    return null
  }
}

export const getCurrentUserAlt = async () => {
  const { data: { user }, error } = await supabase.auth.getUser()
  if (error) throw error
  return user
}

export const onAuthStateChange = (callback) => {
  try {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      callback(session?.user || null)
    })
    return subscription
  } catch (error) {
    console.error('Error setting up auth state change:', error)
    return { unsubscribe: () => {} }
  }
}

export const onAuthStateChangeAlt = (callback) => {
  return supabase.auth.onAuthStateChange(callback)
}

export const sendDemoRequest = async (email, name, company, message) => {
  const { data, error } = await supabase.functions.invoke('sendDemoRequest', {
    body: { email, name, company, message }
  })
  
  if (error) throw error
  return data
}
