import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import axios from 'axios'

const MAX_MESSAGE_LENGTH = 200
const API_BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:3000'

export default function DemoRequestModal({ isOpen, setIsOpen }) {
  const { t } = useTranslation()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [emailTouched, setEmailTouched] = useState(false)
  const [messageError, setMessageError] = useState('')

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email) && !email.includes('+')
  }

  const validateMessage = (message) => {
    if (message.length > MAX_MESSAGE_LENGTH) {
      return t('requestDemo.error.messageTooLong', { max: MAX_MESSAGE_LENGTH })
    }
    return ''
  }

  const handleEmailChange = (e) => {
    const newEmail = e.target.value.replace(/[^a-zA-Z0-9@._-]/g, '')
    setFormData(prev => ({
      ...prev,
      email: newEmail
    }))
    
    if (emailTouched) {
      if (newEmail && !validateEmail(newEmail)) {
        setEmailError(t('auth.signUp.error.invalidEmail'))
      } else {
        setEmailError('')
      }
    }
  }

  const handleEmailBlur = () => {
    setEmailTouched(true)
    if (formData.email && !validateEmail(formData.email)) {
      setEmailError(t('auth.signUp.error.invalidEmail'))
    } else {
      setEmailError('')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (emailError || !validateEmail(formData.email)) {
      setEmailTouched(true);
      setEmailError(t('auth.signUp.error.invalidEmail'));
      return;
    }

    const messageValidation = validateMessage(formData.message);
    if (messageValidation) {
      setMessageError(messageValidation);
      return;
    }

    setIsSubmitting(true);
    
    try {
      const response = await axios.post(`${API_BASE_URL}/demo/request`, {
        name: formData.name,
        email: formData.email,
        message: formData.message
      });

      if (response.data.success) {
        toast.success(t('requestDemo.success'), {
          duration: 5000,
          position: 'top-center'
        });
        setIsOpen(false);
        setFormData({ name: '', email: '', message: '' });
        setEmailError('');
        setEmailTouched(false);
        setMessageError('');
      } else {
        throw new Error(response.data.message || t('requestDemo.error.unknown'));
      }
    } catch (error) {
      console.error('Error sending demo request:', error);
      const errorMessage = error.response?.data?.message || error.message || t('requestDemo.error.unknown');
      toast.error(errorMessage, {
        duration: 5000,
        position: 'top-center'
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'email') return // Email has its own handler
    
    if (name === 'message') {
      setMessageError(validateMessage(value))
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    style={{ background: 'none', border: 'none', padding: 0, outline: 'none', cursor: 'pointer' }}
                    className="focus:outline-none focus:ring-0"
                  >
                    <span className="sr-only">{t('requestDemo.close')}</span>
                    <XMarkIcon className="h-6 w-6 text-gray-400 hover:text-gray-500 transition-colors duration-200" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <Dialog.Title as="h3" className="text-center text-base font-semibold leading-6 text-gray-900">
                    {t('requestDemo.title')}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-center text-gray-500">
                      {t('requestDemo.description')}
                    </p>
                  </div>
                  <form onSubmit={handleSubmit} className="mt-6 space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('auth.signUp.email')}
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          required
                          value={formData.email}
                          onChange={handleEmailChange}
                          onBlur={handleEmailBlur}
                          placeholder={t('auth.signUp.emailPlaceholder')}
                          className={`block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ${
                            emailTouched && emailError ? 'ring-red-300' : 'ring-gray-300'
                          } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                        />
                        {emailError && (
                          <p className="mt-2 text-sm text-red-600">
                            {emailError}
                          </p>
                        )}
                      </div>
                    </div>

                    <div>
                      <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('auth.signUp.fullName')}
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          required
                          value={formData.name}
                          onChange={handleChange}
                          placeholder={t('auth.signUp.fullNamePlaceholder')}
                          className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="flex justify-between items-center">
                        <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
                          {t('requestDemo.message')}
                        </label>
                        <span className={`text-xs ${formData.message.length > MAX_MESSAGE_LENGTH ? 'text-red-500' : 'text-gray-500'}`}>
                          {formData.message.length}/{MAX_MESSAGE_LENGTH}
                        </span>
                      </div>
                      <div className="mt-2">
                        <textarea
                          name="message"
                          id="message"
                          required
                          rows={4}
                          maxLength={MAX_MESSAGE_LENGTH}
                          value={formData.message}
                          onChange={handleChange}
                          placeholder={t('requestDemo.messagePlaceholder')}
                          className={`block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ${
                            messageError ? 'ring-red-300' : 'ring-gray-300'
                          } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                        />
                        {messageError && (
                          <p className="mt-2 text-sm text-red-600">
                            {messageError}
                          </p>
                        )}
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        disabled={isSubmitting || !!messageError || !!emailError}
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {isSubmitting ? (
                          <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                        ) : (
                          t('requestDemo.submit')
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
