import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { startTransition } from 'react';

export function useNavigateWithTransition() {
  const navigate = useNavigate();
  return (...args) => {
    startTransition(() => {
      navigate(...args);
    });
  };
}

export function useSearchParamsWithTransition() {
  const [searchParams, setSearchParams] = useSearchParams();
  const setSearchParamsWithTransition = (...args) => {
    startTransition(() => {
      setSearchParams(...args);
    });
  };
  return [searchParams, setSearchParamsWithTransition];
}

export function useParamsWithTransition() {
  return useParams();
}
